import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { CommonButtonRowWidget, UrlService, UtilityService } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { CourseActivityFeedbackResponse, CourseActivityVideo, CoursePrompt, Quiz, Topic } from '@interfaces';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { FuncService } from '../../services';
import { getIconForTopicMaterialType } from '../get-icon-for-topic-material-type';
import { LearningFeedbackPart } from './feedback/feedback.part';
import { LearningLabelsService } from './learning-labels.service';
import { LearningPromptPart } from './prompt/prompt.part';
import { LearningQuizPart } from './quiz/quiz.part';
import { LearningVideoPart } from './video/video.part';
import { LearningWorkbookPart } from './workbook/workbook.part';

interface Material {
	id: number,
	name: string,
	icon: string,
	url: string,
}

interface SessionTopic {
	index: number,
	topicName: string,
	topicGoals: string,
	/**
	 * Returns a true if there are materials added to the topic
	 */
	hasMaterials: boolean,
	materialAvailableDate?: string,
	topicMaterials: Material[],
}

@Component({
	selector: 'learning-page',
	standalone: true,
	imports: [
		MobilePagePart,
		RouterLink,
		CommonButtonRowWidget,
		NgbAccordionModule,
		FormsModule,
		LearningWorkbookPart,
		LearningPromptPart,
		LearningQuizPart,
		LearningFeedbackPart,
		LearningVideoPart,
	],
	templateUrl: './learning.page.html'
})

export class LearningPage implements OnInit {
	private frame = inject(MobileFrameService);
	public labels = inject(LearningLabelsService);
	private func = inject(FuncService);
	private urlService = inject(UrlService);
	public util = inject(UtilityService);

	public data = signal<{ courses: boolean, topics: Topic[], } | undefined>(undefined);


	public workbook = signal({ infoText: "Do this thing in the workbook...Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.", completedUTC: 234 });
	public prompt = signal<CoursePrompt>({
		courseActivityResponseId: 0,
		markdownText: '# Intro\nGo ahead, play around with the editor! Be sure to check out **bold** and *italic* styling, or even [links](https://google.com). You can type the Markdown syntax, use the toolbar, or use shortcuts like `cmd-b` or `ctrl-b`.\n\n## Lists\nUnordered lists can be started using the toolbar or by typing `* `, `- `, or `+ `. Ordered lists can be started by typing `1. `.\n\n##### Unordered\n* Lists are a piece of cake\n* They even auto continue as you type\n* A double enter will end them\n* Tabs and shift-tabs work too\n\n##### Ordered\n1. Numbered lists...\n2. ...work too!',
		promptAnswer: '',
		promptText: 'Prompt Message will go here',
	});

	public video = signal<CourseActivityVideo>({
		courseVideoActivityId: 1,
		timeInSeconds: 30,
		wistiaId: '4yykj3zgh1',
		ratingQuestion: `Was this content helpful?`,
		transcript: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim, officiis, quaerat nulla dignissimos autem magnam nisi laboriosam a, placeat minus alias dolorem reiciendis illo veritatis similique corporis aspernatur quod tempora. Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto quos quae cum rerum aliquam recusandae officiis atque facilis obcaecati enim voluptatibus distinctio voluptatum illo ea porro, hic praesentium architecto! Quo. Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita ut nihil labore culpa nisi incidunt quos, quod quasi quisquam aperiam natus cupiditate explicabo ea doloribus, rerum neque omnis, ipsam cumque.`,
	});

	public feedback = signal<CourseActivityFeedbackResponse | undefined>(undefined);

	public quiz = signal<Quiz>({
		courseQuizActivityId: 1,
		quizId: 1,
		// attemptSubmittedUTC: 123456,
		questions: [
			{
				correctQuizQuestionChoiceId: 2,
				correctReasonText: `lorem ipsum`,
				questionText: `What does Mvp stand for?`,
				questionType: 'Radio',
				quizQuestionId: 1,
				quizQuestionsId: 1,
				selectedChoiceId: 2,
				choices: [
					{ quizQuestionChoiceId: 1, choiceText: 'Option 1' },
					{ quizQuestionChoiceId: 2, choiceText: 'Option 2' },
					{ quizQuestionChoiceId: 3, choiceText: 'Option 3' },
					{ quizQuestionChoiceId: 4, choiceText: 'Option 4' },
					{ quizQuestionChoiceId: 5, choiceText: 'Option 5' },
				]
			},
			{
				correctQuizQuestionChoiceId: 12,
				correctReasonText: `lorem ipsum`,
				questionText: `What does Mvp stand for? Question 2`,
				questionType: 'Radio',
				quizQuestionId: 2,
				quizQuestionsId: 2,
				selectedChoiceId: 13,
				choices: [
					{ quizQuestionChoiceId: 11, choiceText: 'Option 1' },
					{ quizQuestionChoiceId: 12, choiceText: 'Option 2' },
					{ quizQuestionChoiceId: 13, choiceText: 'Option 3' },
					{ quizQuestionChoiceId: 14, choiceText: 'Option 4' },
					{ quizQuestionChoiceId: 15, choiceText: 'Option 5' },
				]
			}
		]
	});

	public topics = computed(() => {
		const data = this.data();

		if (!data || data && data.courses) return [];

		const isSpanish = this.urlService.isSpanish();


		const topics: SessionTopic[] = (data?.topics ?? []).map((topic, idx) => {
			const topicMaterials: Material[] = [];
			const hasMaterials = !!topic.topicMaterials.length;

			topicMaterials.push(
				...topic.topicMaterials
					.sort((a, b) => a.displayOrder - b.displayOrder)
					.map((topicMaterial, i) => ({
						id: i + 1,
						icon: getIconForTopicMaterialType(topicMaterial.topicMaterialTypeId),
						name: topicMaterial.name,
						url: topicMaterial.url,
					})),
			);

			return {
				index: idx + 1,
				topicName: topic.longNameLabel,
				topicGoals: isSpanish ? topic.goalsES : topic.goalsEN,
				hasMaterials,
				topicMaterials,
			};
		});


		return topics;

	});

	constructor() {
		this.frame.setUrlMetadata({ url: `/learning`, pageName: 'Learning', backUrl: `/` });
	}

	async ngOnInit() {

		const data = await this.func.learning.get();
		this.data.set(data);

	}


	onWorkbookCompleted(value: boolean) {
		console.log('Workbook completed', value);
	}

	setPromptAnswer(value: string) {
		this.prompt.set({ ...this.prompt(), promptAnswer: value });
	}


	commentResponse(value: string) {
		this.feedback.set({
			courseActivityResponseId: 1,
			comment: value,
			feedbackRating: this.feedback()?.feedbackRating || 0,
		});

	}

	ratingResponse(rating: number) {
		this.feedback.set({
			courseActivityResponseId: 1,
			feedbackRating: rating,
			comment: this.feedback()?.comment,
		});
	}

}