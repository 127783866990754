import { AfterViewInit, Component, computed, inject, input, OnDestroy } from '@angular/core';
import { CommonThumbsUpDownRatingWidget, WistiaService, ThumbsUpDown, ThumbsUpDownRating } from '@eforall/common';
import { CourseActivityVideo } from '@interfaces';
import { MarkdownModule, provideMarkdown } from 'ngx-markdown';
import { LearningLabelsService } from '../learning-labels.service';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'learning-video-part',
	standalone: true,
	imports: [
		MarkdownModule,
		CommonThumbsUpDownRatingWidget,
		NgbAccordionModule,
	],
	providers: [provideMarkdown()],
	templateUrl: './video.part.html'
})

export class LearningVideoPart implements AfterViewInit, OnDestroy {

	public labelsService = inject(LearningLabelsService);
	public wistiaService = inject(WistiaService);

	public readonly video = input.required<CourseActivityVideo>();

	public rating = computed<ThumbsUpDown>(() => {
		const video = this.video();

		return {
			text: video.ratingQuestion,
			rating: video.videoRating,
		} as ThumbsUpDown;

	});

	public loadVideo = computed(() => {
		const video = this.video();
		this.wistiaService.replaceVideo(video.wistiaId, false);
		this.wistiaService.seekVideo(video.furthestWatchedSecond || 0);
		this.wistiaService.setWidth(396);
		return true;
	});


	ngAfterViewInit(): void {
		this.wistiaService.onNgAfterViewInit();
	}

	ngOnDestroy(): void {
		this.wistiaService.onNgOnDestroy();
	}

	public updateVideoRating(rating: ThumbsUpDownRating) {
		
	}

}