import { Injectable, computed, inject, signal } from "@angular/core";
import { CommonTextField, CommonTextFieldConfig } from "@eforall/common";
import { GoalsService } from "../goals.service";

@Injectable({ providedIn: 'root' })
export class UpdateGoalFormService {

	public goalsService = inject(GoalsService);

	public accTeamGoalId = signal<number | undefined>(undefined);


	public goal = computed(() => {
		const accTeamGoalId = this.accTeamGoalId();
		const goalsAndAccess = this.goalsService.goalsAndAccess();

		if (accTeamGoalId && goalsAndAccess) return goalsAndAccess.goals.find(goal => goal.accTeamGoalId == accTeamGoalId);

		return undefined;

	});


	readonly summary: CommonTextField = {
		config: signal<CommonTextFieldConfig>({ label: 'Summary', min: 3, max: 60, fixCase: true, multiLine: false, isSpanish: this.goalsService.urlService.isSpanish() }),
		actualValue: computed(() => this.goal()?.summary || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const goalsAndAccess = this.goalsService.goalsAndAccess()
			const goal = this.goal();
			if (goalsAndAccess && goalsAndAccess.canEditGoal && goal && goal.summary !== value) {
				goal.summary = value;
				await this.goalsService.updateGoal(goal);
				this.goalsService.setGoalsData(goal);
			}
		},
		error: signal(''),
	};

	readonly details: CommonTextField = {
		config: signal<CommonTextFieldConfig>({ label: 'Details', min: 1, max: 2000, fixCase: true, multiLine: true, isSpanish: this.goalsService.urlService.isSpanish() }),
		actualValue: computed(() => this.goal()?.summary || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const goalsAndAccess = this.goalsService.goalsAndAccess()
			const goal = this.goal();
			if (goalsAndAccess && goalsAndAccess.canEditGoal && goal && goal.details !== value) {
				goal.details = value;
				await this.goalsService.updateGoal(goal);
				this.goalsService.setGoalsData(goal);
			}
		},
		error: signal(''),
	};


	readonly progress: CommonTextField = {
		config: signal<CommonTextFieldConfig>({ label: 'Progress', min: 0, max: 2000, fixCase: true, multiLine: true, isSpanish: this.goalsService.urlService.isSpanish() }),
		actualValue: computed(() => this.goal()?.progress || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const goalsAndAccess = this.goalsService.goalsAndAccess()
			const goal = this.goal();
			if (goalsAndAccess && goalsAndAccess.canEditGoal && goal && goal.progress !== value) {
				goal.progress = value;
				await this.goalsService.updateGoal(goal);
				this.goalsService.setGoalsData(goal);
			}
		},
		error: signal(''),
	};


	public viewComments() {
		const goal = this.goal();
		if (goal?.comments.length) this.goalsService.flyoutService.showGoalComments(goal.comments);
	}

}