import { Injectable, computed } from "@angular/core";
import { BaseLabelService } from "../../services";

@Injectable({ providedIn: 'root' })
export class LearningLabelsService extends BaseLabelService {

	topicNoMaterialsMsg = computed(() => this.getLabel(['This topic does not have any course material.', 'Este tema no tiene ningún material de curso.']));
	completedWorkbookCheckbox = computed(() => this.getLabel(['I have completed this activity in the workbook', 'ES:I have completed this activity in the workbook']));
	question = computed(() => this.getLabel(['Question', 'Pregunta']));
	answer = computed(() => this.getLabel(['Answer (select one)', 'Respuesta (seleccione una)']));

}