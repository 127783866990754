@if(quiz(); as quiz){
<div class="d-flex flex-column gap-4">

	@if(quiz.attemptSubmittedUTC){
	<common-message>You got {{quiz.percentCorrect}}% correct.</common-message>
	}

	@for(question of questions(); track $index; let idx = $index){
	<div>
		<h4>
			{{labelsService.question()}} {{idx+ 1}}: {{question.label}}
		</h4>

		<div class="d-flex flex-column gap-2">
			@if(quiz.attemptSubmittedUTC){
			<common-message [alertType]="question.correctChoiceId !== question.field.actualValue() ? 'error':'success'">
				@if(question.correctChoiceId !== question.field.actualValue()){
				<span>This is an incorrect answer.</span>
				}
				@else {
				<span>This is a correct answer.</span>
				}
				<a class="link ms-1" (click)="openFlyoutMessage(question.correctChoiceReason)">Click here to read
					why</a>
			</common-message>
			}

			<common-radio-field [field]="question.field"></common-radio-field>
		</div>
	</div>
	}
</div>
}