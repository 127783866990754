import { Component, computed, inject, input, output, signal } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CommonButtonColumnWidget, CommonButtonRowWidget, CommonMessageWidget, CommonTextFieldConfig, CommonTextFieldWidget, UrlService } from '@eforall/common';
import { MarkdownModule, provideMarkdown } from 'ngx-markdown';
import { LearningLabelsService } from '../learning-labels.service';
import { FormsModule } from '@angular/forms';
import { CoursePrompt } from '@interfaces';

@Component({
	selector: 'learning-prompt-part',
	standalone: true,
	imports: [
		MarkdownModule,
		CommonButtonRowWidget,
		RouterLink,
		FormsModule,
		CommonButtonColumnWidget,
		CommonMessageWidget,
		CommonTextFieldWidget,
	],
	providers: [provideMarkdown()],
	templateUrl: './prompt.part.html'
})

export class LearningPromptPart {

	public labelsService = inject(LearningLabelsService);
	public urlService = inject(UrlService);

	public readonly prompt = input.required<CoursePrompt>();
	public readonly promptAnswered = output<string>();


	readonly promptAnswer = computed(() => {
		const prompt = this.prompt();
		return {
			config: signal<CommonTextFieldConfig>({
				label: 'Answer',
				min: prompt.responseMinLength ?? 1,
				max: prompt.responseMaxLength ?? 1000,
				fixCase: true,
				multiLine: true,
				minHeight: 15,
				isSpanish: this.urlService.isSpanish()
			}),
			actualValue: computed(() => this.prompt().promptAnswer || ''),
			pendingValue: signal<string | null>(null),
			saving: signal(false),
			save: async (value: string) => {
				console.log('prompt answer', value);
				this.promptAnswered.emit(value);
			},
			error: signal(''),
		};
	})
}