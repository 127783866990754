import { Component, inject, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CommonButtonRowWidget } from '@eforall/common';
import { MobileFrameService, MobileListWidget, MobilePagePart } from '@eforall/mobile';
import { MyTeamService } from './my-team.service';

@Component({
	selector: 'my-team-page',
	standalone: true,
	imports: [
		MobilePagePart,
		CommonButtonRowWidget,
		RouterLink,
		MobileListWidget,
	],
	templateUrl: './my-team.page.html'
})

export class CohortMyTeamPage implements OnInit {
	private frame = inject(MobileFrameService);
	public myTeamService = inject(MyTeamService);

	constructor() {
		this.frame.setUrlMetadata({ url: '/cohort/my-team', pageName: 'My Team', backUrl: '/cohort' });
	}


	async ngOnInit() {
		await this.myTeamService.loadData();
	}

}