import { Injectable, signal } from '@angular/core';
declare const Wistia: any;

interface wq {
	id: string,
	onReady: (video: unknown) => void,
}

export interface WistiaPlayer {
	/**
	 * Replaces the content of the current video with the video identified by hashedId.
	 */
	replaceWith: (hashedId: string, options: { autoPlay: boolean, fitStrategy: 'contain' | 'cover' | 'fill' | 'none' }) => void,
	/**
	 * Sets the height of the video container to val in pixels
	 * If constrain: true is passed as an option, then the height of the video will also be updated to maintain the correct aspect ratio.
	 */
	height: (px: number, options?: { constrain: boolean }) => void,
	/**
	 * Returns the percent of the video that has been watched as a decimal between 0 and 1.
	 */
	percentWatched: () => number,
	/**
	 * Returns the number of unique seconds that have been watched for the video
	 */
	secondsWatched: () => number,

	/**
	 * Returns an array where each index represents the number of times the viewer has watched each second of the video.
	 */
	secondsWatchedVector: () => number[],
	/**
	 * Seeks the video to the time defined by val. It is expected that val is a decimal integer specified in seconds.
	 */
	time: (seconds: number) => void,
	/**
	 * Sets the height of the video to val in pixels
	 * If constrain: true is passed as an option, then the height of the video will also be updated to maintain the correct aspect ratio.
	 */
	videoHeight: (px: number, options: { constrain: boolean }) => void,
	/**
	 * Sets the width of the video to val in pixels
	 * If constrain: true is passed as an option, then the width of the video will also be updated to maintain the correct aspect ratio.
	 */
	videoWidth: (px: number, options: { constrain: boolean }) => void,

	/**
	 * Sets the width of the video container to val in pixels
	 * If constrain: true is passed as an option, then the width of the video will also be updated to maintain the correct aspect ratio.
	 */
	width: (px: number, options?: { constrain: boolean }) => void,
}


@Injectable({ providedIn: 'root' })
export class WistiaService {

	private _viewInited = signal<boolean>(false);


	/**
	 * Called in a page or view ngAfterViewInit so the service
	 * knows that the Wistia API can find and attach itself to
	 * the 'wistia_player' div element.
	 */
	public async onNgAfterViewInit() {
		this._viewInited.set(true);
	}


	/**
	 * After a page or view is destroyed
	 */
	public async onNgOnDestroy() {
		this._viewInited.set(false);
	}


	/**
	 * Waits until onNgAfterViewInit() has been called because the
	 * page or view DOM has been rendered. Then, the synchronous
	 * Wistia api matcher is used to get a reference to the player.
	 */
	private getPlayer(): WistiaPlayer | undefined {
		let player: WistiaPlayer | undefined = undefined;

		if (this._viewInited()) {
			player = Wistia.api(`wistia_player`);
			if (!player) console.error(`Couldn't find wistia_player`);
		}

		return player;
	}


	/**
	 * Gets a reference to the wistia player for the div element with
	 * an id of 'wistia_player' and then instructs the Wistia API to
	 * load and show the video associated with the provided hashedId
	 */
	public async replaceVideo(hashedId: string, autoPlay = false) {

		const player = await this.getPlayer();
		if (!player) return;

		player.replaceWith(hashedId, { autoPlay, fitStrategy: 'contain' });

	}


	/**
	 * Seeks the video to the time defined by val. 
	 * It is expected that val is a decimal integer specified in seconds.
	 */
	public async seekVideo(lastSecondWatched: number) {

		const player = await this.getPlayer();
		if (!player) return;

		player.time(lastSecondWatched);

	}


	/**
	 * Gets a reference to the wistia player for the div element with
	 * an id of 'wistia_player' and instructs it to resize the player
	 * to the provided width and height.
	 */
	public async setWidthAndHeight(width: number, height: number, aspectRatio = true) {

		const player = await this.getPlayer();
		if (!player) return;

		player.width(width);
		player.height(height);
	}

	public async setWidth(width: number, aspectRatio = true) {

		const player = await this.getPlayer();
		if (!player) return;

		player.videoWidth(width, { constrain: aspectRatio });
	}

}