import { Injectable, computed } from "@angular/core";
import { BaseLabelService } from "../../../../services";

@Injectable({ providedIn: 'root' })
export class BusinessLabelsService extends BaseLabelService {

	feedbackOverview = computed(() => this.getLabel([
		'Below you will find feedback provided to you and the role of the person that provided it. Keep in mind that the feedback is from volunteers which come from many different backgrounds and points of view. We hope you’ll find this feedback helpful in your business growth and/or it gives you insight on the way an outsider perceives and understands your business. Feel free to reach out to the program manager with any questions.',
		'A continuación, encontrará los comentarios proporcionados a usted y el rol de la persona que los proporcionó. Tenga en cuenta que los comentarios provienen de voluntarios que provienen de diferentes orígenes y puntos de vista. Esperamos que este comentario le resulte útil para el crecimiento de su negocio y / o le brinde una idea de la forma en que un extraño percibe y entiende su negocio. No dude en comunicarse con el gerente del programa si tiene alguna pregunta.',
	]));
}