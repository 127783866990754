<mobile-page-part>

	<div class="info-content">
		@if(form.goal(); as goal){
		<h3>{{form.goalsService.util.text.singularOrPluralWithCount(goal.comments.length, 'Comment', 'Comments')}}</h3>
		}
		@else {
		<small>Not authorized</small>
		}
	</div>

	@if(form.goal(); as goal){

	<div class="white-box">
		<h4>{{goal.summary}}</h4>
		<div>
			Goal created: {{form.goalsService.getDate(goal.createdUTC)}}
		</div>
		<div>
			Created By: {{goal.createdByPersonName}}
		</div>
		<div>
			Last Updated: {{form.goalsService.getDate(goal.updatedUTC)}}
		</div>
		<div>
			Updated By: {{goal.updatedByPersonName}}
		</div>

		@if(goal.comments.length > 0){
		<common-button-row [center]="true">
			<button class="btn btn-primary btn-large" (click)="form.viewComments()">View {{form.goalsService.util.text.singularOrPluralWithCount(goal.comments.length, 'Comment', 'Comments')}}</button>
		</common-button-row>
		}
	</div>


	@if(form.goalsService.access().canEditGoal){
	<div class="d-flex flex-column gap-3">

		<common-text-field [field]="form.summary"></common-text-field>
		<common-text-field [field]="form.details"></common-text-field>
		<common-text-field [field]="form.progress"></common-text-field>

	</div>
	}


	}
	@else {
	<common-message alertType="warning">Not authorized</common-message>
	}


</mobile-page-part>