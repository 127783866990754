<mobile-page-part>

	<div class="info-content">
		@if(accTeamCohort(); as accTeamCohort){
		<h3>{{util.text.singularOrPluralWithCount(accTeamCohort.teams.length, 'Venture', 'Ventures')}}</h3>
		}
		@else {
		<small>Not Authorized</small>
		}
	</div>


	@if(accTeamCohort(); as accTeamCohort){
	<div class="white-box">
		<h4>{{accTeamCohort.accName}}</h4>
		<div>Classes Start: {{getDate(accTeamCohort.classStartUTC)}}</div>
		<div>Meeting Times: {{accTeamCohort.meetingTimes}}</div>
		<div>Program Manager: {{accTeamCohort.pmName}}</div>
		<div>Contact: <a target='_blank' href='mailto:{{accTeamCohort.pmEmail}}'>{{accTeamCohort.pmEmail}}</a></div>
	</div>

	@if(list(); as list){
	<div>
		<h4>My Team</h4>
		<mobile-list [items]="list.myTeam"></mobile-list>
	</div>
	<div>
		<h4>My Cohort</h4>
		<mobile-list [items]="list.teams"></mobile-list>
	</div>
	}
	}
	@else {
	<common-message [alertType]="'warning'">Not a team yet. TBD</common-message>
	}

</mobile-page-part>