import { Component, computed, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthService, CommonButtonRowWidget, CommonIconTextWidget, UrlService } from '@eforall/common';
import { MobileFlyoutBodyPart, MobileFlyoutFooterPart, MobileFlyoutFrame, MobileFlyoutHeaderPart, MobileFrameService, MobileMenuItem } from '@eforall/mobile';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../../app/app.service';

@Component({
	selector: 'public-user-menu-flyout-part',
	standalone: true,
	imports: [
		CommonIconTextWidget,
		CommonButtonRowWidget,
		MobileFlyoutBodyPart,
		MobileFlyoutFooterPart,
		MobileFlyoutHeaderPart,
		MobileFlyoutFrame,
		RouterModule,
	],
	templateUrl: './user-menu-flyout.part.html',
	styleUrl: './user-menu-flyout.part.scss'
})
export class PublicUserMenuFlyoutPart {
	private activeOffcanvas = inject(NgbActiveOffcanvas);
	private app = inject(AppService);
	public frame = inject(MobileFrameService);
	private urlService = inject(UrlService);

	private auth = inject(AuthService);

	public languageText = computed(() => {
		const isSpanish = this.urlService.isSpanish();
		return isSpanish ? 'Switch to English' : 'Cambiar al español';
	});

	public menu = computed(() => {
		const user = this.app.data().user;
		const isSpanish = this.urlService.isSpanish();

		if (!user) return [];

		let menu: MobileMenuItem[] = [];

		const prospectMenu: MobileMenuItem[] = [
			{ label: 'Applications', routerLink: '/applications', iconStyle: 'fa-duotone', icon: 'fa-file-pen', },
			{ label: 'Feedback', routerLink: '/feedback', iconStyle: 'fa-duotone', icon: 'fa-message-smile', },
			{ label: 'Signed Agreements', routerLink: '/agreements', iconStyle: 'fa-duotone', icon: 'fa-file-signature', },
			{ label: 'Profile', routerLink: '/profile', iconStyle: 'fa-duotone', icon: 'fa-user', },
		];

		const cohortMenu: MobileMenuItem[] = [
			{ label: 'Calendar', routerLink: '/calendar', iconStyle: 'fa-duotone', icon: 'fa-calendars', },
			{ label: 'Learning', routerLink: '/learning', iconStyle: 'fa-duotone', icon: 'fa-books', },
			{ label: 'Goals', routerLink: '/goals', iconStyle: 'fa-duotone', icon: 'fa-chart-mixed', },
			{ label: 'Surveys', routerLink: '/surveys', iconStyle: 'fa-duotone', icon: 'fa-clipboard-list-check', },
			{ label: 'Cohort', routerLink: '/cohort', iconStyle: 'fa-duotone', icon: 'fa-people-group', },
			{ label: 'My Team', routerLink: '/cohort/my-team', iconStyle: 'fa-duotone', icon: 'fa-people', },
		];

		const alumMenu: MobileMenuItem[] = [
			{ label: 'Volunteer', url: isSpanish ? 'https://eforall.org/es/voluntariado/' : 'https://eforall.org/volunteering/', iconStyle: 'fa-duotone', icon: 'fa-handshake-angle', },
		];


		if (user.phase == 'Alum') {
			menu = [
				...alumMenu,
				...cohortMenu,
				...prospectMenu,
			];
		}
		else if (user.phase == 'Cohort') {
			menu = [
				...cohortMenu,
				...prospectMenu,
			];
		}
		else menu = prospectMenu;




		menu.push({ label: 'eforall.org', url: 'https://eforall.org', iconStyle: 'fa-duotone', icon: 'fa-globe-pointer', });

		// if (user.isTechAdmin) {
		menu.push({ label: 'Examples', routerLink: '/examples', iconStyle: 'fa-duotone', icon: 'fa-question', });
		// }

		return menu;
	});

	signOut() {
		this.auth.signOut();
		this.activeOffcanvas.dismiss();
	}

	toogleLanguage() {
		// this.urlService.toogleLanguage();
		this.activeOffcanvas.dismiss();
	}

	close() {
		this.activeOffcanvas.close();
	}
}