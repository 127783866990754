import { Component, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { CommonButtonRowWidget, CommonSelectFieldWidget, CommonTextFieldWidget } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { BusinessLabelsService } from './business-labels.service';
import { CohortBusinessFormService } from './business-form.service';

@Component({
	selector: 'business-page',
	standalone: true,
	imports: [
		MobilePagePart,
		RouterLink,
		CommonButtonRowWidget,
		CommonTextFieldWidget,
		CommonSelectFieldWidget,
		FormsModule,
	],
	templateUrl: './business.page.html'
})

export class MyTeamBusinessPage implements OnInit{
	private frame = inject(MobileFrameService);
	public labels = inject(BusinessLabelsService);
	public form = inject(CohortBusinessFormService);

	constructor() {
		this.frame.setUrlMetadata({ url: `/cohort/my-team/business-details`, pageName: 'Business Details', backUrl: `/cohort/my-team` });
	}


	async ngOnInit() {
		await this.form.loadData();
	}


}