import { NgClass } from '@angular/common';
import { Component, input, output } from '@angular/core';

@Component({
	selector: 'common-smiley-rating',
	standalone: true,
	imports: [NgClass],
	templateUrl: './common-smiley-rating.widget.html',
	styleUrls: ['./common-smiley-rating.widget.scss'],
})
export class CommonSmileyRatingWidget {

	public rating = input<number>();
	public updateRating = output<number>();

	private focusedRating: number | null = null;


	public setRating(value: number) {
		this.updateRating.emit(value);
	}

	public setFocus(value: number) {
		this.focusedRating = value;
	}

	public clearFocus() {
		this.focusedRating = null;
	}

	public isFocused(value: number) {
		return this.focusedRating === value;
	}

}