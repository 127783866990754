import { Component, computed, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { UrlService } from '@eforall/common';
import { MobileFrameService } from '../mobile-frame.service';
import { MobileMenuItem } from '../mobile-frame-config';


@Component({
	selector: 'mobile-nav-bar-part',
	standalone: true,
	imports: [RouterModule],
	templateUrl: './mobile-nav-bar.part.html',
	styleUrls: ['./mobile-nav-bar.part.scss'],
})
export class MobileNavBarPart {

	public frame = inject(MobileFrameService);
	private router = inject(Router);
	private url = inject(UrlService);

	public navbarItems = computed<MobileMenuItem[]>(() => {
		
		const items = this.frame.config().navBarItems;

		//
		// IMPORTANT: This line is required to recompute when
		// the language changes. Don't remove it.
		//
		const languageId = this.url.languageId();

		//
		// Create an updated set of navbar items with the language prepended
		//
		return items.map(item => ({
			...item,
			routerLink: item.routerLink ? this.url.withCurrentLanguage(item.routerLink): undefined,
		}));

	});

	isActive(routerLink: string): boolean {
		return this.router.url === routerLink;
	}

}