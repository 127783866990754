import { Injectable, computed, signal } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs';
import { AngularEnvironment, EnvironmentService } from '../env';
import { BASE_URL, getBaseUrl } from './get-base-url';
import { ENGLISH, SPANISH, UrlLanguageId } from './url-language-id';


@Injectable({ providedIn: 'root' })
export class UrlService {

	public readonly languageId = signal<UrlLanguageId>(ENGLISH);
	public readonly isSpanish = computed(() => this.languageId() === SPANISH);
	public readonly url = signal<string>('');


	constructor(
		private router: Router,
		private env: EnvironmentService,
	) {

		//
		// Listen for whenever the rout changes and handle it
		//
		this.router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				map(event => this.router.url),
			)
			.subscribe(this.handleNavigation.bind(this))

	}


	/**
	 * Deterine the base url for a specific language. The environment and app config
	 * of the running application will also be used.  To get the base url for the
	 * language of the running application, just read the urlService.baseUrl property.
	 * @param languageId 
	 */
	public getBaseUrl(env: AngularEnvironment, languageId: UrlLanguageId): BASE_URL {
		return getBaseUrl(env, languageId);
	}


	/**
	 * Called each time the app navigates. It handles the language slug
	 * if supported and determines and sets the body css classes.
	 */
	private async handleNavigation(url: string) {

		const environment = await this.env.getEnvironmentPromise();

		//
		// Handle language if it is supported
		//
		if (environment.supportsSpanish) {

			const urlParts = url.split('/');

			if (urlParts[1] !== 'en' && urlParts[1] !== 'es') {
				//
				// No language slug, so prepend 'en'
				//
				if (url !== '/') console.error(`Url ${url} has been changed to /en${url}`);
				if (url.endsWith('/')) url = url.substring(0, url.length - 1);
				window.location.href = environment.baseUrl + 'en' + url;
			}

			//
			// Signal the current language
			//
			if (urlParts[1] == 'es') this.languageId.set(SPANISH);
			else this.languageId.set(ENGLISH);
		}


		this.url.set(url);



		//
		// Calculate body classes and attach to body element
		//

		const isSpanish = this.isSpanish();

		const bodyCssClasses: string[] = [
			'env-' + environment.name.toLowerCase(),
			isSpanish ? 'lang-spanish' : 'lang-english',
			'app-' + environment.appName.toLowerCase(),
			'auth-' + environment.authType.toLowerCase(),
			...environment.bodyCssClasses,
		];

		const body = document.getElementsByTagName('body')[0];
		body?.classList.add(...bodyCssClasses);

	}


	/**
	 * This function is a simple pass through if env.supportsSpanish
	 * is false. 
	 * 
	 * Otherwise, it ensures that the url provided has the language
	 * path. The passed in url will be checked if whether it has the
	 * current language. If not, it will be prepended.
	 * 
	 */
	public withCurrentLanguage(url: string) {

		const supportsSpanish: boolean = this.env.getEnvironment()?.supportsSpanish;
		if (!supportsSpanish) return url;

		const spanish = this.isSpanish();

		if (!url.startsWith('/')) url = '/' + url;

		if (url.startsWith('/en')) {
			if (spanish) return '/es' + url.substring(3);
			else return url;
		}

		else if (url.startsWith('/es')) {
			if (!spanish) return '/en' + url.substring(3);
			else return url;
		}

		else return (spanish ? '/es' : '/en') + url;
	}


	/**
	 * Switch the URL and language to a new one.
	 * Note: Only works if the environment.supportsSpanish is true.
	 */
	public setLanguage(urlLanguageId: UrlLanguageId) {

		//
		// Bail if Spanish not supported
		//
		const supportsSpanish: boolean = this.env.getEnvironment()?.supportsSpanish;
		if (!supportsSpanish) return;

		//
		// Bail if already this language
		//
		if (this.languageId() == urlLanguageId) return;

		//
		// Update the language and url
		//
		this.languageId.set(urlLanguageId);
		this.url.set(this.withCurrentLanguage(this.url()));

		//
		// Mimic as if we navigated so the body classes are updated
		//
		this.handleNavigation(this.url());
	}


	/**
	 * If the language is currently Spanish, set it to English.
	 * Otherwise, set it to Spanish.
	 */
	public toggleLanguage() {
		const urlLanguageId = this.languageId() == SPANISH ? ENGLISH : SPANISH;
		this.setLanguage(urlLanguageId);
	}
	
}