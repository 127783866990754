<mobile-page-part>

	<div class="info-content">
		@if(myTeamService.team(); as team){
		<h3>
			{{myTeamService.util.text
			.singularOrPluralWithCount(team.entrepreneurs.length + team.mentors.length,
			'Team Member',
			'Team Members')}}
		</h3>
		}
		@else {
		<small>No team</small>
		}
	</div>


	@if(myTeamService.team(); as team){
	<div class="white-box">
		<h4>{{team.data.name}}</h4>
		<div>Org Type: {{team.data.orgType}}</div>
		<div>Industry: {{team.data.industry}}</div>
		<div>Offerings: {{team.data.offerings}}</div>
		<div>Status: {{team.data.status}}</div>

		<common-button-row [center]='true'>
			@if(team.data.canEdit){
			<button class="btn btn-standard" routerLink="business-details">Edit</button>
			}
			<button class="btn btn-standard" routerLink="/applications/{{team.data.applicationId}}">View
				Application</button>
		</common-button-row>
	</div>


	<div>
		<h4>Entrepreneurs</h4>
		@if(team.entrepreneurs.length){
		<mobile-list [items]="team.entrepreneurs"></mobile-list>
		}
		@else {
		<div class="text-center">No Entrepreneurs</div>
		}
	</div>

	<div>
		<h4>Mentors</h4>
		@if(team.mentors.length){
		<mobile-list [items]="team.mentors"></mobile-list>
		}
		@else {
		<div class="text-center">No Mentors</div>
		}
	</div>
	}


</mobile-page-part>