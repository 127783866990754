import { Component, HostBinding, computed, input } from '@angular/core';

@Component({
	selector: 'common-progress-bar',
	standalone: true,
	templateUrl: './common-progress-bar.widget.html',
	styleUrls: ['./common-progress-bar.widget.scss'],
})
export class CommonProgressBarWidget {

	public items = input.required<boolean[]>();

}