import { Component, computed, inject, input, output, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { CommonButtonColumnWidget, CommonButtonRowWidget, CommonMessageWidget, CommonSmileyRatingWidget, CommonTextFieldConfig, CommonTextFieldWidget, UrlService } from '@eforall/common';
import { CourseActivityFeedbackResponse } from '@interfaces';
import { LearningLabelsService } from '../learning-labels.service';

@Component({
	selector: 'learning-feedback-part',
	standalone: true,
	imports: [
		CommonButtonRowWidget,
		RouterLink,
		FormsModule,
		CommonButtonColumnWidget,
		CommonMessageWidget,
		CommonSmileyRatingWidget,
		CommonTextFieldWidget,
	],
	templateUrl: './feedback.part.html'
})

export class LearningFeedbackPart {

	public labelsService = inject(LearningLabelsService);
	public urlService = inject(UrlService);

	public readonly feedback = input<CourseActivityFeedbackResponse>();
	public readonly feedbackRatingResponse = output<number>();
	public readonly feedbackCommentResponse = output<string>();


	readonly feedbackComment = computed(() => {
		const feedback = this.feedback();
		return {
			config: signal<CommonTextFieldConfig>({
				label: 'Additional Comments',
				min: 0,
				max:300,
				fixCase: true,
				multiLine: true,
				minHeight: 10,
				isSpanish: this.urlService.isSpanish()
			}),
			actualValue: computed(() => feedback?.comment || ''),
			pendingValue: signal<string | null>(null),
			saving: signal(false),
			save: async (value: string) => {
				console.log('feedback comment', value);
				this.feedbackCommentResponse.emit(value);
			},
			error: signal(''),
		};
	});



	public setRating(rating: number) {
		this.feedbackRatingResponse.emit(rating);
	}
}