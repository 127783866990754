import { Component, inject, OnInit, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { CommonButtonRowWidget, CommonTextFieldWidget } from '@eforall/common';
import { MobileFrameService, MobileListWidget, MobilePagePart } from '@eforall/mobile';
import { GoalsLabelsService } from './goals-labels.service';
import { GoalsService } from './goals.service';
import { AddGoalFormService } from './add-goal-form.service';

@Component({
	selector: 'goals-page',
	standalone: true,
	imports: [
		MobilePagePart,
		RouterLink,
		CommonButtonRowWidget,
		MobileListWidget,
		CommonTextFieldWidget,
		FormsModule,
	],
	templateUrl: './goals.page.html'
})

export class GoalsPage implements OnInit {
	private frame = inject(MobileFrameService);
	public labels = inject(GoalsLabelsService);
	public form = inject(AddGoalFormService);

	constructor() {
		this.frame.setUrlMetadata({ url: `/goals`, pageName: 'Goals', backUrl: `/` });
	}

	async ngOnInit() {
		await this.form.goalsService.loadData();
	}


	addAGoal() {
		this.form.openAddForm();
	}

}