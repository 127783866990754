@if(loadVideo()){
<div class="d-flex flex-column gap-2">
	<div id="wistia_player" class="wistia_embed wistia_async_ijcn56ewqi"></div>
	<div class="white-box">

		<common-thumbs-up-down-rating [rating]="rating()"
			(updateRating)="updateVideoRating($event)"></common-thumbs-up-down-rating>
	</div>

	<div ngbAccordion [closeOthers]="true">
		<div ngbAccordionItem [collapsed]="true">
			<h2 ngbAccordionHeader>
				<button ngbAccordionButton class="p-2">Transcript</button>
			</h2>
			<div ngbAccordionCollapse>
				<div ngbAccordionBody class="p-2">
					<ng-template>
						<markdown [data]="video().transcript"></markdown>
					</ng-template>
				</div>
			</div>
		</div>
	</div>
</div>
}