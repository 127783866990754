import { Component, inject } from '@angular/core';
import { ApplicationMakeChangesFlyoutPart, ApplicationParticipantFlyoutPart, ApplicationReadonlyFlyoutPart, ApplicationSubmitFlyoutPart, CalendarEventFlyoutPart, GoalCommentsFlyoutPart } from '../../../pages';
import { FlyoutService } from '../flyout.service';
import { PublicMessageFlyoutPart } from '../message/message-flyout.part';
import { PublicUserMenuFlyoutPart } from '../user-menu/user-menu-flyout.part';


@Component({
	selector: 'flyout-container-part',
	standalone: true,
	imports: [
		PublicUserMenuFlyoutPart,
		PublicMessageFlyoutPart,
		ApplicationSubmitFlyoutPart,
		ApplicationParticipantFlyoutPart,
		ApplicationMakeChangesFlyoutPart,
		ApplicationReadonlyFlyoutPart,
		CalendarEventFlyoutPart,
		GoalCommentsFlyoutPart,
	],
	templateUrl: './flyout-container.part.html',
	styles: [`
		:host {
			height:100%;
		}
	`],
})
export class PublicFlyoutContainerPart {

	public flyoutService = inject(FlyoutService);

}