import { Component, computed, inject, input, output, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { CommonButtonColumnWidget, CommonButtonRowWidget, CommonMessageWidget, CommonOptionsFieldConfig, CommonRadioField, CommonRadioFieldWidget, CommonTextFieldConfig, CommonTextFieldWidget, UrlService } from '@eforall/common';
import { Quiz } from '@interfaces';
import { MarkdownModule, provideMarkdown } from 'ngx-markdown';
import { LearningLabelsService } from '../learning-labels.service';
import { FlyoutService } from '../../../services';

@Component({
	selector: 'learning-quiz-part',
	standalone: true,
	imports: [
		MarkdownModule,
		CommonButtonRowWidget,
		RouterLink,
		FormsModule,
		CommonButtonColumnWidget,
		CommonMessageWidget,
		CommonRadioFieldWidget,
	],
	providers: [provideMarkdown()],
	templateUrl: './quiz.part.html'
})

export class LearningQuizPart {

	public labelsService = inject(LearningLabelsService);
	public urlService = inject(UrlService);
	private flyoutService = inject(FlyoutService);

	public readonly quiz = input.required<Quiz>();
	public readonly submitQuiz = output<string>();


	readonly questions = computed<{
		label: string,
		correctChoiceId: number,
		correctChoiceReason: string,
		field: CommonRadioField<number>
	}[]>(() => {
		const quiz = this.quiz();

		const quizQuestions: {
			label: string,
			correctChoiceId: number,
			correctChoiceReason: string,
			field: CommonRadioField<number>
		}[] = [];

		for (const question of quiz.questions) {
			quizQuestions.push({
				correctChoiceId: question.correctQuizQuestionChoiceId,
				correctChoiceReason: question.correctReasonText,
				label: question.questionText,
				field: {
					config: signal<CommonOptionsFieldConfig<number>>({
						label: this.labelsService.answer()!,
						options: question.choices.map(choice => ({ text: choice.choiceText, value: choice.quizQuestionChoiceId })),
						required: true,
						disabled: !!quiz.attemptSubmittedUTC,
					}),
					actualValue: computed(() => question.selectedChoiceId || 0),
					pendingValue: signal<number | null>(null),
					saving: signal(false),
					save: async (value: number) => {
						console.log(question.questionText, value);
					},
					error: signal(''),
				}
			});

		}

		return quizQuestions;
	});



	public openFlyoutMessage(message: string) {
		this.flyoutService.showMarkdownMessage(message, 'Reason')
	}
}