import { computed } from '@angular/core';
import { MobileFrameConfig, MobileMenuItem } from '@eforall/mobile';
import { AppService, FlyoutService } from '../../services';


export function getMobileFrameConfig(app: AppService, flyoutService: FlyoutService) {
	return computed(() => {
		const user = app.data().user;

		let navBarItems: MobileMenuItem[] = [
			{
				label: 'Programs',
				routerLink: '/programs',
				iconStyle: 'fa-regular',
				icon: 'fa-hand-holding-heart color-eforall-blue',
				iconColor: 'color-eforall-blue',
			},
			{
				label: 'Applications',
				routerLink: '/applications',
				iconStyle: 'fa-regular',
				icon: 'fa-file-pen color-eforall-blue',
				iconColor: 'color-eforall-blue',
			},
			{
				label: 'Home',
				routerLink: '/',
				iconStyle: 'fa-regular',
				icon: 'fa-home',
				iconColor: 'color-eforall-blue',
			},
			{
				label: 'Profile',
				routerLink: '/profile',
				iconStyle: 'fa-regular',
				icon: 'fa-user color-eforall-blue',
				iconColor: 'color-eforall-blue',
			},
			{
				label: 'eforall.org',
				url: 'https://eforall.org/',
				iconStyle: 'fa-regular',
				icon: 'fa-globe-pointer color-eforall-blue',
				iconColor: 'color-eforall-blue',
			},
		];

		if (user.phase !== 'Prospect') {
			navBarItems = [
				{
					label: 'Learning',
					routerLink: '/learning',
					iconStyle: 'fa-regular',
					icon: 'fa-books color-eforall-blue',
					iconColor: 'color-eforall-blue',
				},
				{
					label: 'Goals',
					routerLink: '/goals',
					iconStyle: 'fa-regular',
					icon: 'fa-chart-mixed color-eforall-blue',
					iconColor: 'color-eforall-blue',
				},
				{
					label: 'Home',
					routerLink: '/',
					iconStyle: 'fa-regular',
					icon: 'fa-home',
					iconColor: 'color-eforall-blue',
				},
				{
					label: 'Cohort',
					routerLink: '/cohort',
					iconStyle: 'fa-regular',
					icon: 'fa-people-group color-eforall-blue',
					iconColor: 'color-eforall-blue',
				},
				{
					label: 'Calendar',
					routerLink: '/calendar',
					iconStyle: 'fa-regular',
					icon: 'fa-calendars color-eforall-blue',
					iconColor: 'color-eforall-blue',
				},
			]
		}



		const items: MobileFrameConfig = {
			user: {
				publicUserId: user.personId,
				firstName: user.firstName,
				lastName: user.lastName,
				email: user.emails?.find(email => email.isDefault)?.email || '',
				avatar: '',
				mobilePhone: user.phone,
				department: undefined
			},
			navBarItems,

			openUserMenu: () => flyoutService.showUserMenu(),
		}

		return items;
	});
}