<div class="d-flex flex-column gap-2">
	<markdown [data]="workbook().infoText"></markdown>

	<div class="mb-2 d-flex flex-row gap-2">
		<input class="form-check-input fs-6" type="checkbox" name="field-checkbox-workbook" id="acknowledgeCheckbox"
			[ngModel]="!!workbook().completedUTC" (ngModelChange)="completedWorkbook.emit($event)" [disabled]="!!workbook().completedUTC">
		<label class="form-check-label" for="acknowledgeCheckbox">
			{{labelsService.completedWorkbookCheckbox()}}
		</label>
	</div>
</div>