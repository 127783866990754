import { Injectable, computed, inject, signal } from "@angular/core";
import { CommonEmailField, CommonGeneralFieldConfig, CommonTextField, CommonTextFieldConfig, UrlService } from "@eforall/common";
import { GoalsService } from "./goals.service";
import { FuncService } from "../../services";

@Injectable({ providedIn: 'root' })
export class AddGoalFormService {

	public goalsService = inject(GoalsService);


	private values = signal<{ summary: string, details: string }>({
		summary: '',
		details: '',
	});



	readonly summary: CommonTextField = {
		config: signal<CommonTextFieldConfig>({ label: 'Summary', min: 3, max: 60, fixCase: true, multiLine: false, isSpanish: this.goalsService.urlService.isSpanish() }),
		actualValue: computed(() => this.values().summary),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const values = this.values();
			this.values.set({ ...values, summary: value });
		},
		error: signal(''),
	};

	readonly details: CommonTextField = {
		config: signal<CommonTextFieldConfig>({ label: 'Details', min: 1, max: 2000, fixCase: true, multiLine: true, isSpanish: this.goalsService.urlService.isSpanish() }),
		actualValue: computed(() => this.values().details),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const values = this.values();
			this.values.set({ ...values, details: value });
		},
		error: signal(''),
	};


	public showAddForm = signal(false);


	async createGoal() {
		const access = this.goalsService.access();
		if (access && access.canAddGoal) {

			const valid = this.checkIfValid();
			if (valid) {
				const values = this.values();
				await this.goalsService.addGoal(values.summary, values.details);
				this.closeAddForm();
			}
			else {
				this.goalsService.flyoutService.showMarkdownMessage('Please fill out all the required fields in order to create a new goal. TBD', 'Create Goal')
			}
		}
	}

	checkIfValid() {
		const values = this.values();
		return !this.summary.error() && !this.details.error()
			&& !!values.summary && !!values.details;
	}

	resetForm() {
		this.values.set({
			summary: '',
			details: '',
		});
	}


	closeAddForm() {
		this.resetForm();
		this.showAddForm.set(false);
	}

	openAddForm() {
		this.resetForm();
		this.showAddForm.set(true);
	}


}