import { Component, inject, input, output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CommonButtonColumnWidget, CommonButtonRowWidget, CommonMessageWidget } from '@eforall/common';
import { MarkdownModule, provideMarkdown } from 'ngx-markdown';
import { LearningLabelsService } from '../learning-labels.service';
import { FormsModule } from '@angular/forms';

@Component({
	selector: 'learning-workbook-part',
	standalone: true,
	imports: [
		MarkdownModule,
		CommonButtonRowWidget,
		RouterLink,
		FormsModule,
		CommonButtonColumnWidget,
		CommonMessageWidget,
	],
	providers: [provideMarkdown()],
	templateUrl: './workbook.part.html'
})

export class LearningWorkbookPart {

	public labelsService = inject(LearningLabelsService);

	public readonly workbook = input.required<{ infoText: string, completedUTC?: number }>();

	public readonly completedWorkbook = output<boolean>();

}