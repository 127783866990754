<mobile-page-part>

	<div class="info-content">
		@if(data(); as data){
		@if(data.courses){<h3>Accelerator Course</h3>}
		@else if(data.topics.length){<h3>{{util.text.singularOrPluralWithCount(data.topics.length, 'Topic Available',
			'Topics Available')}}</h3>}
		@else {<h3>No Ongoing Session</h3>}
		}
	</div>

	<learning-video-part [video]="video()"></learning-video-part>

	<div class="white-box">
		<h4>Feedback</h4>
		<learning-feedback-part [feedback]="feedback()" (feedbackCommentResponse)="commentResponse($event)" (feedbackRatingResponse)="ratingResponse($event)"></learning-feedback-part>
	</div>

	<div class="white-box">
		<h4>Quiz</h4>
		<learning-quiz-part [quiz]="quiz()"></learning-quiz-part>
	</div>

	<div class="white-box">
		<h4>Workbook</h4>
		<learning-workbook-part [workbook]="workbook()"
			(completedWorkbook)="onWorkbookCompleted($event)"></learning-workbook-part>
	</div>

	<div class="white-box">
		<h4>Prompt</h4>
		<learning-prompt-part [prompt]="prompt()" (promptAnswered)="setPromptAnswer($event)"></learning-prompt-part>
	</div>




	@if(data(); as data){

	@if(data.courses){
	<div>This is acc has courses.</div>
	}
	@else if(topics().length) {
	@if(topics(); as topics){
	<div ngbAccordion [closeOthers]="true">
		@for (topic of topics; track $index;) {
		<div ngbAccordionItem>
			<div ngbAccordionHeader>
				<button ngbAccordionButton class="btn btn-flat p-2">
					{{topic.topicName}}
				</button>
			</div>

			<div ngbAccordionCollapse>
				<div ngbAccordionBody class="p-2">
					<ng-template>
						<p>{{topic.topicGoals}}</p>


						<h4>Materials:</h4>

						@if(!topic.hasMaterials){
						<i class="color-gray">
							{{labels.topicNoMaterialsMsg()}}
						</i>
						}


						@if(topic.topicMaterials.length){

						<table class="table table-sm table-bordered">
							@for(material of topic.topicMaterials; track material.id;){
							<tr>
								<td class="p-1">{{material.id}}:</td>
								<td class="p-1"><i class="fa-fw {{material.icon}}"></i></td>
								<td class="p-1"><a [href]="material.url" target="_blank">
										{{material.name}}
									</a>
								</td>
							</tr>
							}
						</table>
						}
					</ng-template>
				</div>
			</div>
		</div>
		}

	</div>
	}
	}
	@else {
	<div>No Ongoing Sessions. TBD</div>
	}

	}

</mobile-page-part>